<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
     <div class="logo-wrapper">
       <img src="../assets/Logo.jpg" alt="">
     </div>
  </div>
</template>

<script>
// @ is an alias to /src
//  import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: 'HomeView',
  components: {
    //  HelloWorld,
  },
};
</script>

<style lang="scss">
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .logo-wrapper {
    max-width: 600px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
